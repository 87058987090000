<template>
  <div>
    <b-overlay :show="isLoadingControlAccessData" rounded class="">
      <b-card v-if="requestData">
        <b-row class="mb-1 pr-0">
          <b-col>
            <h4>Solicitud de Mantenimiento por {{ requestData.ownerName }}</h4>
            <h6>Mantenimiento a la propiedad  {{requestData.resortsname || ''}} | {{requestData.housingnumber || ''}} </h6>
          </b-col>
          <b-col cols="2">
            <b-button class="float-right" size="sm" variant="warning" :to="{ name: 'maintenance-requests'}">
              <feather-icon icon="ArrowLeftIcon" size="16" class="align-middle" style="color: white" />
            </b-button>
          </b-col>
          <b-col cols="12">
            <b-alert show class="p-1 text-center" :variant="badgeStatus(requestData).variant"> Solicitud <strong>{{ badgeStatus(requestData).statusName }}</strong> </b-alert>
          </b-col>
          <b-col md="12" v-if="!!requestData.reviewData">
            <div class="border rounded p-1 mb-1">
              <b-row align-v="center">
                <!-- <b-col cols="12">
                  <h6>Datos de </h6>
                </b-col> -->
                <b-col>
                  <label class="font-weight-bolder">{{ badgeStatus(requestData).statusName }} Por:</label><br>
                  {{ requestData.reviewData.authFullname }} <br>

                  <label class="font-weight-bolder">Revisado el:</label><br>
                  {{ formatThisDate(requestData.reviewData.createdate) }}
                </b-col>
                <b-col md="10" class="border-left">
                  <label class="font-weight-bolder">Motivo:</label><br>
                  {{ requestData.reviewData.notes }}
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>


        <b-row>
          <!-- Tipo de Accesso -->
          <b-col md="">
            <div class="border rounded p-1 mb-1 request-card">
              <h5>Datos de Solicitud</h5>
              <b-row>
                <b-col md="">
                  <label class="font-weight-bolder"> Motivo: </label><br>
                  {{requestData.reason}}
                </b-col>
                <b-col md="12" class="mt-1">
                  <label class="font-weight-bolder"> Descripción: </label><br>
                    {{requestData.description}}
                </b-col>
              </b-row>
            </div>
          </b-col>
          <b-col>
            <b-row>
              <!-- Fechas del Mantenimiento -->
              <b-col md="12">
                <div class="border rounded p-1 mb-1">
                  <h6>Fechas de Mantenimiento</h6>
                  <b-row>
                    <b-col md="">
                      <label class="font-weight-bolder">Fecha Inicio: </label><br>
                      {{requestData.startDate}}
                    </b-col>

                    <b-col md="">
                      <label class="font-weight-bolder">Fecha Fin: </label><br>
                      {{requestData.endDate}}
                    </b-col>
                  </b-row>
                </div>
              </b-col>
              <!-- Datos del proveedor -->
              <b-col>
                <div class="border rounded p-1 provider-card">
                  <h5>Datos del Proveedor</h5>
                  <b-row>
                    <b-col cols="">
                      <label class="font-weight-bolder">Nombre del Proveedor</label><br>
                      {{requestData.providerName}}
                    </b-col>
                    <b-col md="">
                      <label class="font-weight-bolder">Tipo</label><br>
                      {{requestData.providerType}}
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="">
                      <label class="font-weight-bolder">Teléfono</label><br>
                      {{requestData.providerPhone}}
                    </b-col>
                    <b-col cols="">
                      <label class="font-weight-bolder">Email</label><br>
                      {{requestData.providerEmail}}
                    </b-col>
                  </b-row>

                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <hr>

        <b-row>
          <!-- Files -->
          <b-col v-if="requestData.files.length > 0">
            <div class="border rounded p-1 mb-1">
              <h5>Documentos</h5>
              <div class="upload__img-wrap mt-1">
                <div v-for="file in requestData.files" :key="file.name" class="upload__img-box text-center">
                  <span>
                    <a
                      :href="imgUrl  +'/'+ file.file"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <feather-icon icon="FileTextIcon" size="4x"/> <br>
                      <label class="mini">{{file.name}}</label>
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </b-col>
          <!-- Images -->
          <b-col>
            <div class="border rounded p-1" v-if="requestData.images.length > 0">
              <h5>Imágenes</h5>
              <b-row>
                <b-col md="12">
                  <div class="upload__img-wrap">
                    <div v-for="img in requestData.images" :key="img.name" class="upload__img-box">
                      <!-- <b-img :src="imgUrl + img.image" fluid alt="Responsive image" class="img-bg mt-1" ></b-img> -->
                      <a
                        :href="imgUrl  +'/'+ img.image"
                        target="_blank"
                        rel="noopener noreferrer"
                      > <div :style="imgGallery(img)" class='img-bg'></div>
                      </a>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-col>

          <b-col cols="12" v-if="requestData.status != 2 && requestData.status != 3 && requestData.status != 4">
            <b-button
              @click="chooseResponse()"
              v-if="can('fivesclub_web_owners_show_maintenance_request_auth_button')"
              :disabled="isSavingControlAccessData"
              variant="success"
              class="float-right mt-2 ml-1"
              type="button"
            > <b-spinner v-if="isSavingControlAccessData" small/>
              <feather-icon icon="CheckIcon" size="16" v-else/> Autorizar
            </b-button>

            <b-button
              @click="goToReject()"
              v-if="can('fivesclub_web_owners_show_maintenance_request_reject_button') && requestData.status != 4"
              :disabled="isSavingControlAccessData"
              variant="danger"
              class="float-right mt-2"
              type="button"
            > <b-spinner v-if="isSavingControlAccessData" small/>
              <feather-icon icon="XIcon" size="16" v-else/> Rechazar
            </b-button>
          </b-col>
        </b-row>

        <ModalRejectRequest :requestData="requestData" @choose-response="setResponse"/>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex"
import { sweetAlert, showAlertMessage, formatDateOnly } from '@/helpers/helpers'
import { acl } from "@/modules/auth/mixins/acl"
import ModalRejectRequest from "@/modules/fivesClub/components/catalogs/ownersWebsite/ModalRejectRequest"

export default {
  mixins: [acl],
  async created() {
    await this.init()
  },
  components: {
    ModalRejectRequest
  },
  data() {
    return {
      isLoadingControlAccessData: false,
      isSavingControlAccessData: false,
      imgUrl: process.env.VUE_APP_IMG_SRC_API,
      idRequest: this.$route.params.id,

      requestData: {
        reason: null,
        description: null,
        startDate: null,
        endDate: null,

        provider: null,
        providerName: null,
        providerType: null,
        providerPhone: null,
        providerEmail: null,

        dateOut: null,
        timeIn: null,
        timeOut: null,

        files:[],
        images:[],
      },
      reasons: []
    }
  },
  computed: {
    ...mapState("auth", ["user"]),
  },
  methods: {
    ...mapActions('fivesClubCatalogs', ['fetchMaintenanceRequests', 'setMaintenanceResolution']),
    async init(){
			if(this.isANumber(this.idRequest)){
        this.isLoadingControlAccessData = true
        const accessRegister = await this.fetchMaintenanceRequests({idRequest: this.idRequest})
        if (accessRegister != []) {
          this.requestData = structuredClone(accessRegister)
        }
        this.isLoadingControlAccessData = false
      }
    },
    isANumber(string){
      return string ? !isNaN( string ) && string > 0 : false
    },
    formatThisDate(date){
      return formatDateOnly(date, 'es')
    },
    async chooseResponse(){
      const swalert = {
        title: `Autorizando mantenimiento para la vivienda ${this.requestData.housingnumber}`,
        message: "¿Desea continuar?",
        icon: 'success',
        confirmButton: "Continuar",
        cancelButton: "Cancelar",
      }

      const {isConfirmed} = await sweetAlert(swalert)
      if ( isConfirmed ) await this.setResponse({request: null, choice: 2})

    },
    async setResponse(payload) {
      const {choice} = payload
      this.isSavingControlAccessData=true
      // console.log({id: this.idRequest, idUser:this.user.idUser, resolution: choice, reviewNotes: this.requestData.reviewNotes});
      const respuesta = await this.setMaintenanceResolution({id: this.idRequest, idUser:this.user.idUser, resolution: choice, reviewNotes: this.requestData.reviewNotes})
      if(!!respuesta) {
        showAlertMessage(`Ok`, 'InfoIcon', `Se ${choice == 2 ? 'autorizó':"rechazó"} la solicitud correctamente`, 'success', 4000, 'bottom-right')
        await this.init()
      }
      this.isSavingControlAccessData=false
    },
    goToReject() {
      this.$root.$emit("bv::show::modal", "modal-reject-maintenance-request-" + this.requestData.id)
    },
		badgeStatus(request){
			const { status } = request

			let variant =  'outline-info'
			let statusName = "Pending"

			if(status === '1' ){
				variant = 'info'
				statusName = "Pendiente" //  1-> Pendiente
			}

			if(status === '2' ){
				variant = 'success'
				statusName = "Confirmada" // 2-> es confirmada
			}

			if( status === '3' ){
				variant = 'danger'
				statusName = "Cancelada"
			}

			if( status === '4' ){
				variant = 'warning'
				statusName = "Rechazada"
			}

			return {variant, statusName}
		},
    imgGallery(img){
      return img ? `background-image: url('${this.imgUrl  +'/'+ img.image }');` : ''
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.white-background{
  background-color: white !important;
}
.upload__img-wrap {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}
.upload__img-box {
  width: 10rem;
  padding: 0 10px;
  margin-bottom: 1rem;
}
.img-bg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  padding-bottom: 100%;
}
.upload__img-close {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: rgba(179, 11, 11, 0.5);
  position: absolute;
  top: 3px;
  right: 1px;
  text-align: center;
  line-height: 24px;
  z-index: 1;
  cursor: pointer;
}
.upload__img-close:after {
  content: '\2716';
  font-size: 14px;
  color: white;
}
.thumb1 {
  // background: url(blah.jpg) 50% 50% no-repeat; /* 50% 50% centers image in div */
  width: 250px;
  height: 250px;
}

.thumb1 a {
  display: block;
  width: 250px;
  height: 250px;
}

.request-card{
  height: 20rem;
  max-height: 20rem;
}
.provider-card{
  height: 12rem;
  max-height: 12rem;
}
.mini{
  font-size: x-small;
}
</style>